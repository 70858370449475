import React, { useState, useEffect } from 'react';
import { Slider } from '@/components/ui/slider';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { baseUrl } from '@/services/api-config';
import { StepStatus } from '@/utils/Interfaces';
import axios from 'axios';

// Initialize Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_DETAILS!);

interface PaymentFormProps {
  onPaymentMethodStored: (paymentMethodId: string) => void;
}

const appearance = {
  theme: 'stripe' as const,
  variables: {
    colorPrimary: '#0066cc',
    colorBackground: '#ffffff',
    colorText: '#30313d',
  }
};

interface PaymentSetupData {
  setupIntentId: string;
  clientSecret: string;
  paymentMethodId: string;
  dailyBudget: number;
}

interface SetupIntentResponse {
  client_secret: string;
  setup_intent_id: string;
  status: string;
  payment_method_id: string | null;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ onPaymentMethodStored }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const createSetupIntent = async () => {
    const response = await fetch(`${baseUrl}/api/create-setup-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Failed to create setup intent');
    }

    return await response.json() as SetupIntentResponse;
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    setLoading(true);
    try {
      // First validate and collect the payment method details
      const { error: elementsError } = await elements.submit();
      
      if (elementsError) {
        setError(elementsError.message || 'Something went wrong');
        return;
      }

      // Create a payment method from the card details
      const { error: createError, paymentMethod } = await stripe.createPaymentMethod({
        elements,
        params: {
          type: 'card',
        },
      });

      if (createError) {
        setError(createError.message || 'Failed to create payment method');
        return;
      }

      if (!paymentMethod) {
        setError('Failed to create payment method');
        return;
      }

      // Create the SetupIntent using your backend
      const setupIntent = await createSetupIntent();
      
      // Store in localStorage
      const paymentData = localStorage.getItem('payment_setup_data');
      const storedData = paymentData ? JSON.parse(paymentData) : {};
      
      const updatedData = {
        ...storedData,
        setupIntentId: setupIntent.setup_intent_id,
        clientSecret: setupIntent.client_secret,
        paymentMethodId: paymentMethod.id,
        validationComplete: true
      };
      
      localStorage.setItem('payment_setup_data', JSON.stringify(updatedData));
      
      // Notify parent component with the payment method ID
      onPaymentMethodStored(paymentMethod.id);
      
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to save payment details');
      console.error('Payment setup error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      {error && <div className="text-red-500 mt-2">{error}</div>}
      <button
        type="submit"
        disabled={!stripe || loading}
        className="mt-4 w-full bg-blue-600 text-white py-2 px-4 rounded disabled:opacity-50"
      >
        {loading ? 'Saving...' : 'Save payment details'}
      </button>
    </form>
  );
};


interface PageProps {
  url: string;
  onStepComplete?: (status: StepStatus) => void;
}

interface PaymentSetupData {
  setupIntentId: string;
  clientSecret: string;
  paymentMethodId: string;
  dailyBudget: number;
}

const SetBudgetPage: React.FC<PageProps> = ({ url, onStepComplete }) => {
  const MINIMUM_BUDGET = 10;
  const [dailyBudget, setDailyBudget] = useState(0);
  const [weeklyInvoice, setWeeklyInvoice] = useState(0);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [storedSetupIntentId, setStoredSetupIntentId] = useState<string | null>(null);
  const [budgetError, setBudgetError] = useState<string | null>(null);
  const [suggestedBudget, setSuggestedBudget] = useState<number | null>(null);
  const [agreements, setAgreements] = useState({
    privacyPolicy: false,
    termsOfService: false
  });

  useEffect(() => {
    // Check for existing payment setup data
    const existingData = localStorage.getItem('payment_setup_data');
    if (existingData) {
      const data = JSON.parse(existingData);
      if (data.setupIntentId) {
        setStoredSetupIntentId(data.setupIntentId);
      }

      // Only set dailyBudget if it's valid (>= MINIMUM_BUDGET)
      if (data.dailyBudget && data.dailyBudget >= MINIMUM_BUDGET) {
        setDailyBudget(data.dailyBudget);
      } else {
        // If stored budget is invalid, initialize localStorage with minimum budget
        localStorage.setItem('payment_setup_data', JSON.stringify({
          ...data,
          dailyBudget: MINIMUM_BUDGET
        }));
      }
    } else {
      // Initialize localStorage with minimum budget if no data exists
      localStorage.setItem('payment_setup_data', JSON.stringify({
        dailyBudget: MINIMUM_BUDGET
      }));
    }

    // Fetch suggested budget
    fetchSuggestedBudget();
  }, [url]);

  useEffect(() => {
    if (!onStepComplete) return;

    const isBudgetValid = dailyBudget >= 10;
    const isSetupIdValid = !!storedSetupIntentId;
    const areAgreementsAccepted = agreements.privacyPolicy && agreements.termsOfService;

    onStepComplete({
      isComplete: isBudgetValid && isSetupIdValid && areAgreementsAccepted,
      message: !isSetupIdValid
        ? 'Please fix payment method'
        : !isBudgetValid
          ? 'Please set a valid daily budget'
          : !areAgreementsAccepted
            ? 'Please accept the Terms of Service and Privacy Policy'
            : undefined
    });
  }, [dailyBudget, storedSetupIntentId, agreements, onStepComplete]);

  useEffect(() => {
    const multiplyFee = dailyBudget * 0.1;
    setWeeklyInvoice(multiplyFee * 7);

    // Store budget in localStorage
    const paymentData = localStorage.getItem('payment_setup_data');
    if (paymentData) {
      const data = JSON.parse(paymentData);
      localStorage.setItem('payment_setup_data', JSON.stringify({
        ...data,
        dailyBudget
      }));
    }
  }, [dailyBudget]);

  const fetchSuggestedBudget = async () => {
    try {
      const suggested_budget = localStorage.getItem(`${url}_SUGGESTED_BUDGET`);

      if (!suggested_budget) {
        console.log(`Fetching suggested budget for domain: [${url}]`);
        const resp = await axios.get(`${baseUrl}/googleads/campaign/budget/suggestion?url=${encodeURIComponent(url)}`);

        if (resp.data.suggested_budget) {
          const budget = Math.max(MINIMUM_BUDGET, Number(resp.data.suggested_budget)/1000000); // Ensure minimum budget
          setSuggestedBudget(budget);
          setDailyBudget(budget);
          
          // Store suggested budget in localStorage
          localStorage.setItem(`${url}_SUGGESTED_BUDGET`, budget.toString());
          
          // Update payment setup data with new budget
          const paymentData = localStorage.getItem('payment_setup_data');
          if (paymentData) {
            const data = JSON.parse(paymentData);
            localStorage.setItem('payment_setup_data', JSON.stringify({
              ...data,
              dailyBudget: budget
            }));
          }
        }
      } else {
        const budget = Math.max(MINIMUM_BUDGET, Number(suggested_budget));
        setSuggestedBudget(budget);
        setDailyBudget(budget);
      }
    } catch (error) {
      console.error('Failed to fetch suggested budget:', error);
      setBudgetError('Unable to load the suggested budget. You can still set your own budget or try refreshing the page.');
      setDailyBudget(MINIMUM_BUDGET); // Set to minimum budget
    }
  };

  const handleAgreementChange = (agreement: 'privacyPolicy' | 'termsOfService') => {
    setAgreements(prev => ({
      ...prev,
      [agreement]: !prev[agreement]
    }));
  };

  const handlePaymentMethodStored = (paymentMethodId: string) => {
    setStoredSetupIntentId(paymentMethodId);

    // Update payment setup data in localStorage
    const paymentData = localStorage.getItem('payment_setup_data');
    if (paymentData) {
      const data = JSON.parse(paymentData);
      const updatedData: PaymentSetupData = {
        ...data,
        paymentMethodId
      };
      localStorage.setItem('payment_setup_data', JSON.stringify(updatedData));
    }
  };

  const handleSetDailyBudget = (item: number) => {
    const curr = localStorage.getItem('payment_setup_data');
    const newBudget = Math.max(10, item); // Ensure minimum budget

    if (curr) {
      localStorage.setItem('payment_setup_data', JSON.stringify({
        ...JSON.parse(curr),
        dailyBudget: newBudget
      }));
    } else {
      localStorage.setItem('payment_setup_data', JSON.stringify({
        dailyBudget: newBudget
      }));
    }

    setDailyBudget(newBudget);
  };

  return (
    <div className="flex flex-col md:flex-row gap-8 p-6 max-w-6xl mx-auto">
      {/* Left Column */}
      <div className="flex-1">
        {/* Logo and Header */}
        <div className="flex items-center gap-2 mb-4">
          <div className="bg-white text-black p-2 rounded">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <path d="M18 8h1a4 4 0 0 1 0 8h-1"></path>
              <path d="M2 8h16v9a4 4 0 0 1-4 4H6a4 4 0 0 1-4-4V8z"></path>
              <line x1="6" y1="1" x2="6" y2="4"></line>
              <line x1="10" y1="1" x2="10" y2="4"></line>
              <line x1="14" y1="1" x2="14" y2="4"></line>
            </svg>
          </div>
          <h1 className="text-2xl font-bold">Multiply</h1>
        </div>

        {/* Pricing Info */}
        <h2 className="text-xl font-semibold mb-2">How our pricing works</h2>
        <p className="text-gray-600 mb-4">
          Google will bill you directly for your ad spend. Each week,
          we'll charge a simple 10% fee based on your spend. No
          surprises, just great results!
        </p>

        {/* Feature List */}
        <ul className="space-y-2">
          {[
            'Google Ads campaign run by Multiply',
            'No hidden fees',
            'Automatic ad optimizations',
            'AI-generated ad content',
            'Customized dashboard',
            '24/7 support'
          ].map((feature, index) => (
            <li key={index} className="flex items-center gap-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-green-500" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      {/* Right Column */}
      <div className="flex-1">
        <div className="bg-white p-6 rounded-lg shadow">
          <h2 className="text-xl font-semibold mb-2">Set your daily ad budget</h2>

          {/* Suggested Budget Alert */}
          {suggestedBudget && (
            <div className="mb-4 p-4 bg-blue-50 border border-blue-200 rounded-md">
              <p className="text-blue-700">
                Based on our AI analysis of your business, we recommend a daily budget
                of ${suggestedBudget.toFixed(2)} for optimal campaign performance.
              </p>
            </div>
          )}

          {/* Error Alert */}
          {budgetError && (
            <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-md">
              <p className="text-red-700">{budgetError}</p>
            </div>
          )}

          <p className="text-gray-600 mb-4">You can adjust your budget at any time.</p>

          {/* Budget Slider */}
          <Slider
            min={10}
            max={100}
            step={1}
            value={[dailyBudget]}
            onValueChange={(value) => handleSetDailyBudget(value[0])}
            className="mb-4"
          />

          {/* Slider Labels */}
          <div className="flex justify-between text-sm text-gray-600 mb-6">
            <span>$10</span>
            <span>$50</span>
            <span>$100</span>
          </div>

          {/* Budget Display */}
          <div className="mb-4">
            <span className="text-2xl font-bold">${dailyBudget.toFixed(2)}/day</span>
            <span className="text-sm text-gray-600 ml-2">USD</span>
          </div>

          {/* Cost Breakdown */}
          <div className="space-y-2 mb-6">
            <div className="flex justify-between">
              <span className="text-gray-600">Google Ads (Billed separately)</span>
              <span>${dailyBudget.toFixed(2)}/day</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Multiply fees (10%)</span>
              <span>${(dailyBudget * 0.1).toFixed(2)}/day</span>
            </div>
          </div>

          {/* Weekly Total */}
          <div className="flex justify-between font-semibold mb-6">
            <span>Estimated weekly invoice</span>
            <span>${weeklyInvoice.toFixed(2)}</span>
          </div>

          {/* Payment Form */}
          <Elements
            stripe={stripePromise}
            options={{ 
              mode: 'setup',
              appearance,
              paymentMethodTypes: ['card'],
              paymentMethodCreation: 'manual' // Add this line
            }}>
            <PaymentForm onPaymentMethodStored={handlePaymentMethodStored} />
          </Elements>

          {/* Success Message */}
          {storedSetupIntentId && (
            <div className="mt-4 p-3 bg-green-50 text-green-700 rounded">
              Payment method saved successfully! You can continue with the next step.
            </div>
          )}

          {/* Terms and Privacy Agreement Section */}
          <div className="mt-6 space-y-4 border-t pt-4">
            <div className="flex items-start gap-2">
              <input
                type="checkbox"
                id="privacyPolicy"
                checked={agreements.privacyPolicy}
                onChange={() => handleAgreementChange('privacyPolicy')}
                className="mt-1"
              />
              <label htmlFor="privacyPolicy" className="text-sm text-gray-600">
                I have read and agree to the{' '}
                <a
                  href="https://docs.google.com/document/d/1qvbx_E6AdSDWLQobaT8HPzR6_Dy5l3c1Nm0DrFhIe_8/view"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open('https://docs.google.com/document/d/1qvbx_E6AdSDWLQobaT8HPzR6_Dy5l3c1Nm0DrFhIe_8/view', '_blank', 'noopener,noreferrer');
                  }}
                >
                  Privacy Policy
                </a>
              </label>
            </div>

            <div className="flex items-start gap-2">
              <input
                type="checkbox"
                id="termsOfService"
                checked={agreements.termsOfService}
                onChange={() => handleAgreementChange('termsOfService')}
                className="mt-1"
              />
              <label htmlFor="termsOfService" className="text-sm text-gray-600">
                I have read and agree to the{' '}
                <a
                  href="https://docs.google.com/document/d/1_1_mAFIjt_Ouj2NTyaGkkSjqF4yCcCBrL8W_Aaiusv0/view"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-600 hover:underline"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open('https://docs.google.com/document/d/1_1_mAFIjt_Ouj2NTyaGkkSjqF4yCcCBrL8W_Aaiusv0/view', '_blank', 'noopener,noreferrer');
                  }}
                >
                  Terms of Service
                </a>
              </label>
            </div>

            {/* Agreement Warning */}
            {(!agreements.privacyPolicy || !agreements.termsOfService) && (
              <div className="mt-4 text-sm text-yellow-600">
                Please accept both the Privacy Policy and Terms of Service to continue.
              </div>
            )}

            {/* Final Note */}
            <div className="mt-4 text-sm text-gray-500">
              <p>Your card won't be charged until you complete the setup process.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetBudgetPage;
import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { toast }from 'react-toastify';

interface KeywordProps {
  positiveKeywords: string[];
  negativeKeywords: string[];
  onUpdateKeywords: (positive: string[], negative: string[]) => void;
}

const ManageKeywords: React.FC<KeywordProps> = ({ positiveKeywords, negativeKeywords, onUpdateKeywords }) => {
  const [positive, setPositive] = useState<string[]>(positiveKeywords);
  const [negative, setNegative] = useState<string[]>(negativeKeywords);
  const [newPositive, setNewPositive] = useState('');
  const [newNegative, setNewNegative] = useState('');

  useEffect(() => {
    setPositive(positiveKeywords);
    setNegative(negativeKeywords);
  }, [positiveKeywords, negativeKeywords]);

  const addKeyword = (type: 'positive' | 'negative') => {
    const newKeyword = type === 'positive' ? newPositive.trim() : newNegative.trim();
    const currentKeywords = type === 'positive' ? positive : negative;

    if (newKeyword && !currentKeywords.includes(newKeyword)) {
      if (type === 'positive') {
        setPositive([...positive, newKeyword]);
        setNewPositive('');
      } else {
        setNegative([...negative, newKeyword]);
        setNewNegative('');
      }
    } else if (currentKeywords.includes(newKeyword)) {
      toast.error(`"${newKeyword}" is already in the ${type} keywords list.`);
    }
  };

  const removeKeyword = (type: 'positive' | 'negative', keyword: string) => {
    if (type === 'positive') {
      setPositive(positive.filter(k => k !== keyword));
    } else {
      setNegative(negative.filter(k => k !== keyword));
    }
  };

  const saveKeywords = () => {
    onUpdateKeywords(positive, negative);
    toast.success('Keywords saved successfully');
  };

  return (
    <div className="space-y-6">
      <h2 className="text-2xl font-bold">Keywords</h2>
      <p className="text-gray-600">
        Words or phrases describing your product or service that you choose to help determine when
        and where your ad can appear.
      </p>
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold mb-2">Positive keywords</h3>
          <p className="text-gray-600 mb-4">Use positive keywords to show ads for relevant searches.</p>
          <Input
            placeholder="Enter a new word or phrase"
            value={newPositive}
            onChange={(e) => setNewPositive(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && addKeyword('positive')}
            className="mb-2"
          />
          <div className="flex flex-wrap gap-2 mb-4">
            {positive.map((keyword, index) => (
              <span key={index} className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-sm flex items-center">
                {keyword}
                <button onClick={() => removeKeyword('positive', keyword)} className="ml-2 text-gray-500 hover:text-gray-700">
                  ×
                </button>
              </span>
            ))}
          </div>
        </CardContent>
      </Card>
      <Card>
        <CardContent className="p-6">
          <h3 className="text-xl font-semibold mb-2">Blocked keywords</h3>
          <p className="text-gray-600 mb-4">Prevent your ad from showing for certain searches.</p>
          <Input
            placeholder="Enter a new word or phrase"
            value={newNegative}
            onChange={(e) => setNewNegative(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && addKeyword('negative')}
            className="mb-2"
          />
          <div className="flex flex-wrap gap-2 mb-4">
            {negative.map((keyword, index) => (
              <span key={index} className="bg-gray-100 text-gray-800 px-2 py-1 rounded-full text-sm flex items-center">
                {keyword}
                <button onClick={() => removeKeyword('negative', keyword)} className="ml-2 text-gray-500 hover:text-gray-700">
                  ×
                </button>
              </span>
            ))}
          </div>
        </CardContent>
      </Card>
      <Button onClick={saveKeywords} className="px-4 py-2 rounded bg-primary text-white hover:bg-primaryDark">Save Changes</Button>
    </div>
  );
};

export default ManageKeywords;
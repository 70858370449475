import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Card, CardContent } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useAuth } from '@/utils/AuthProvider';
import { DOMAIN_KEY } from '@/types/constants';

const OnboardingStartPage: React.FC = () => {
  const { session } = useAuth()
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (session) {
      navigate('/manage-campaign');
    }
  }, [navigate, session]);

  const handleAnalyze = async () => {
    if (!websiteUrl) return;

    setIsAnalyzing(true);
    console.log(`Analyzing website: ${websiteUrl}`);

    // Simulate analysis process
    await new Promise(resolve => setTimeout(resolve, 2000));

    localStorage.setItem(DOMAIN_KEY, websiteUrl);

    // Navigate to the next page
    navigate(`/onboarding?step=0`);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-indigo-50 flex items-start justify-center p-12 overflow-hidden">
      <Card className="w-full max-w-2xl">
        <CardContent className="p-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h1 className="text-3xl font-bold text-gray-800 mb-2">Launch Your Ad Campaign in 5 minutes</h1>
            <p className="text-gray-600 mb-6">
              Enter your website URL below and let our AI analyze your business to create
              highly optimized ad campaigns tailored just for you.
            </p>

            <div className="space-y-4">
              <Input
                type="url"
                placeholder="https://www.your-website.com"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                className="w-full p-3 text-lg"
              />
              <Button
                onClick={handleAnalyze}
                disabled={!websiteUrl || isAnalyzing}
                className="w-full py-3 text-lg text-white"
              >
                {isAnalyzing ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Analyzing...
                  </span>
                ) : (
                  'Start AI Analysis'
                )}
              </Button>
            </div>

            <div className="mt-8">
              <h2 className="text-xl font-semibold text-gray-700 mb-3">What happens next?</h2>
              <ul className="space-y-2">
                {[
                  "We'll analyze your website content and structure",
                  "Our AI will generate tailored ad copy and keywords",
                  "You'll get a preview of potential ad campaigns",
                  "Fine-tune and launch your optimized ads with one click"
                ].map((step, index) => (
                  <motion.li
                    key={index}
                    className="flex items-start"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                  >
                    <svg className="w-6 h-6 text-green-500 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    <span className="text-gray-600">{step}</span>
                  </motion.li>
                ))}
              </ul>
            </div>
          </motion.div>
        </CardContent>
      </Card>
    </div>
  );
};

export default OnboardingStartPage;
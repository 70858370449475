// src/layouts/MainLayout.tsx
import React from 'react';
import NavBar from './NavBar';

const MainLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="min-h-screen bg-background text-neutralDark ">
      <NavBar />
      <main style={{ paddingTop: 73 }}>
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
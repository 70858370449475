import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useAuth } from '@/utils/AuthProvider';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Slider } from '@/components/ui/slider';
import { Button } from '@/components/ui/button';
import { toast } from 'react-toastify';
import { baseUrl } from '@/services/api-config';
import axios from 'axios';

interface OnboardingPaymentInfo {
  setup_intent_id: string;
  payment_id: string;
  budget: number;
}

const ConversionProfile: React.FC = () => {
  const [conversionType, setConversionType] = useState<'leads' | 'purchases'>('leads');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [monthly, setMonthly] = useState('');
  const [campaignId, setCampaignId] = useState(localStorage.getItem('campaignId'));
  const [average, setAverage] = useState('');
  const [profit, setProfit] = useState('');
  const [conversionRate, setConversionRate] = useState<number[]>([25]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [savedLocation, setSavedLocation] = useState<Location | null>(null);
  const { session, isAuthenticated, refreshSession } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // Load location from localStorage if it exists
    const locationData = localStorage.getItem('onboarding_location');
    if (locationData) {
      setSavedLocation(JSON.parse(locationData));
    }

    if (!campaignId) {
      console.log(`Redirecting because campaignId is not set`);
      navigate('/manage-campaign');
    }
  }, []);

  useEffect(() => {
    const isValid = 
      monthly !== '' && 
      average !== '' && 
      profit !== '' && 
      (conversionType === 'purchases' || (conversionType === 'leads' && conversionRate.length > 0));
    setIsFormValid(isValid);
  }, [monthly, average, profit, conversionType, conversionRate]);
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!isFormValid) return;
    setIsSubmitting(true);
  
    try {
      await refreshSession();
      
      // Get payment setup data from localStorage
      const paymentSetupData = localStorage.getItem('payment_setup_data');
      if (!paymentSetupData) {
        throw new Error('Payment setup data not found');
      }
      
      const paymentData = JSON.parse(paymentSetupData);
      
      // Check if we have both required payment fields
      if (!paymentData.setupIntentId || !paymentData.paymentMethodId) {
        throw new Error('Incomplete payment information');
      }
      
      // Construct payment info with both IDs
      const paymentInfo: OnboardingPaymentInfo = {
        setup_intent_id: paymentData.setupIntentId,
        payment_id: paymentData.paymentMethodId,
        budget: Math.floor(paymentData.dailyBudget)
      };
  
      const body = {
        campaign_id: campaignId,
        payment_info: paymentInfo,
        ...(savedLocation && { location: savedLocation }),
        profile: conversionType === 'leads' 
          ? {
              monthly_leads: parseInt(monthly),
              customer_spend: parseFloat(average),
              estimated_profit: parseFloat(profit),
              conversion_rate: conversionRate[0],
            }
          : {
              monthly_sales: parseInt(monthly),
              aov: parseFloat(average),
              est_profit: parseFloat(profit),
            }
      };
  
      const headers = {
        Authorization: `Bearer ${session?.access_token}`,
        'Content-Type': 'application/json',
      };
  
      const response = await axios.post(`${baseUrl}/googleads/campaign/create`, body, { headers });
      
      if (response.status !== 200) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Clean up localStorage
      localStorage.removeItem('payment_setup_data');
      localStorage.removeItem('onboarding_location');
      localStorage.removeItem('campaignId');
      
      // Show success message and redirect
      toast.success('Campaign created successfully!');
      navigate('/manage-campaign');
      
    } catch (error) {
      console.error('Error creating campaign:', error);
      toast.error(
        error instanceof Error && 
        (error.message === 'Payment setup data not found' || error.message === 'Incomplete payment information')
          ? "Payment information missing or incomplete. Please complete payment setup."
          : "Failed to create campaign. Please try again."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardContent className="p-6">
        <h2 className="text-2xl font-bold mb-2">Set up your conversion profile</h2>
        <p className="text-gray-600 mb-6">
          Multiply will use this information to optimize your conversion tracking, ad spend,
          performance, and more.
        </p>

        <form onSubmit={handleSubmit}>
          <RadioGroup
            value={conversionType}
            onValueChange={(value: 'leads' | 'purchases') => setConversionType(value)}
            className="grid grid-cols-2 gap-4 mb-6"
          >
            <div className={`border rounded-lg p-4 ${conversionType === 'leads' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="leads" id="leads" className="sr-only" />
              <Label htmlFor="leads" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Leads</span>
                <span className="text-gray-600">
                  For businesses looking to generate leads. This includes service-based
                  businesses, such as electricians.
                </span>
              </Label>
            </div>
            <div className={`border rounded-lg p-4 ${conversionType === 'purchases' ? 'border-blue-500' : 'border-gray-200'}`}>
              <RadioGroupItem value="purchases" id="purchases" className="sr-only" />
              <Label htmlFor="purchases" className="flex flex-col cursor-pointer">
                <span className="text-xl font-semibold mb-2">Purchases</span>
                <span className="text-gray-600">
                  For businesses looking to drive sales. This includes product-based
                  businesses, such as online stores.
                </span>
              </Label>
            </div>
          </RadioGroup>

          <div className="space-y-4">
            <div>
              <Label htmlFor="monthly">
                {conversionType === 'leads' ? 'Monthly leads' : 'Monthly sales'}
              </Label>
              <Input
                id="monthly"
                type="number"
                placeholder="Enter a number"
                className="mt-1"
                value={monthly}
                onChange={(e) => setMonthly(e.target.value)}
                required
              />
            </div>
            <div>
              <Label htmlFor="average">
                {conversionType === 'leads' ? 'Average customer spend' : 'Average order value'}
              </Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="average"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={average}
                  onChange={(e) => setAverage(e.target.value)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            <div>
              <Label htmlFor="profit">Estimated profit</Label>
              <div className="flex mt-1">
                <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  $
                </span>
                <Input
                  id="profit"
                  type="number"
                  placeholder="Enter a number"
                  className="rounded-l-none"
                  value={profit}
                  onChange={(e) => setProfit(e.target.value)}
                  required
                />
                <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                  USD
                </span>
              </div>
            </div>
            {conversionType === 'leads' && (
              <div>
                <Label htmlFor="conversion-rate">Conversion rate</Label>
                <Slider
                  id="conversion-rate"
                  min={0}
                  max={100}
                  step={1}
                  value={conversionRate}
                  onValueChange={setConversionRate}
                  className="mt-2"
                />
                <div className="flex justify-between text-sm text-gray-500 mt-1">
                  <span>0%</span>
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                </div>
              </div>
            )}
          </div>

          <Button type="submit" className="w-full mt-6 text-white" disabled={isSubmitting || !isFormValid}>
            {isSubmitting ? (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                Creating campaign...
              </motion.div>
            ) : (
              'Create campaign'
            )}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default ConversionProfile;
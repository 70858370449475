import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import OnboardingStartPage from './pages/OnboardingStartPage';
import ManageCampaignPage from './pages/ManageCampaignPage';
import OnboardingContainer from './components/OnboardingContainer';
import GoogleAuthFlowContainer from './pages/GoogleAuthFlowContainer';
import AuthCallback from './pages/AuthCallback';
import { AuthProvider, useAuth } from './utils/AuthProvider';
import { DentistOfficeLandingPage, PersonalInjuryLandingPage } from './pages/VerticalLandingPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App: React.FC = () => {
  const { session } = useAuth();
  return (
    <Router>
      <AuthProvider>
        <MainLayout>
          <Routes>
            <Route path="/" element={<OnboardingStartPage />} />
            <Route path="/manage-campaign" element={<ManageCampaignPage />} />
            <Route path="/onboarding" element={<OnboardingContainer />} />
            <Route path="/onboarding/link-google-account" element={<GoogleAuthFlowContainer />} />
            <Route path="/personal-injury-law" element={<PersonalInjuryLandingPage />} />
            <Route path="/dentistry" element={<DentistOfficeLandingPage />} />
            <Route path="/account/auth/callback" element={<AuthCallback />}/>
          </Routes>
        </MainLayout>
        <ToastContainer />
      </AuthProvider>
    </Router>
  );
};

export default App;
import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import AdLocationSelector from '@/components/AdLocationSelector';
import { StepStatus } from '@/utils/Interfaces';
import { AlertCircle, X } from 'lucide-react';
import { Button } from '@/components/ui/button';

interface LocationTargetingPageProps {
  onStepComplete?: (status: StepStatus) => void;
  onPrevious?: () => void;
}

interface Coordinates {
  lat: number;
  lng: number;
}

interface Location {
  type: 'PROXIMITY' | 'LOCATION';
  address_type?: string;
  geo_target_constant?: string;
  coordinates?: Coordinates;
  radius_miles?: number;
  resource_name: string;
}

const LocationTargetingPage: React.FC<LocationTargetingPageProps> = ({ 
  onStepComplete,
  onPrevious 
}) => {
  const [location, setLocation] = useState<Location | null>(() => {
    const saved = localStorage.getItem('onboarding_location');
    try {
      return saved ? JSON.parse(saved) : null;
    } catch {
      localStorage.removeItem('onboarding_location');
      return null;
    }
  });
  
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null);
  const [targetingType, setTargetingType] = useState<'PROXIMITY' | 'LOCATION' | null>(() => {
    const saved = localStorage.getItem('onboarding_location');
    try {
      const savedLocation = saved ? JSON.parse(saved) : null;
      return savedLocation?.type || null;
    } catch {
      return null;
    }
  });

  // Update step completion status
  useEffect(() => {
    onStepComplete?.({
      isComplete: location !== null,
      message: !location ? 'Please select a location to continue' : undefined
    });
  }, [location, onStepComplete]);

  const handleLocationSelect = useCallback((selected: Location) => {
    setSelectedLocation(selected);
    if (!location) {
      setTargetingType(selected.type);
    }
  }, [location]);

  const handleAddLocation = useCallback(() => {
    if (!selectedLocation) {
      toast.error('Please select a location');
      return;
    }

    if (location && location.type !== selectedLocation.type) {
      toast.error(`Cannot mix ${selectedLocation.type} and ${location.type} targeting types`);
      return;
    }

    setLocation(selectedLocation);
    setSelectedLocation(null);
    localStorage.setItem('onboarding_location', JSON.stringify(selectedLocation));
    toast.success(`${selectedLocation.type === 'LOCATION' ? 'Location' : 'Proximity'} target added successfully`);
  }, [selectedLocation, location]);

  const handleRemoveLocation = useCallback(() => {
    setLocation(null);
    setSelectedLocation(null);
    setTargetingType(null);
    localStorage.removeItem('onboarding_location');
    toast.success('Location target removed');
  }, []);

  const handlePrevious = useCallback(() => {
    setLocation(null);
    setSelectedLocation(null);
    setTargetingType(null);
    localStorage.removeItem('onboarding_location');
    onPrevious?.();
  }, [onPrevious]);

  return (
    <div className="flex-1 p-8">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold">Location Targeting Setup</h1>
        {onPrevious && (
          <Button
            variant="outline"
            onClick={handlePrevious}
          >
            Previous
          </Button>
        )}
      </div>

      <div className="mb-6 p-4 bg-blue-50 border border-blue-200 rounded-lg flex items-start">
        <AlertCircle className="h-5 w-5 text-blue-500 mt-0.5 mr-2" />
        <div>
          <h4 className="font-semibold text-blue-800">Important: Choose Your Targeting Type</h4>
          <p className="text-blue-700">
            Select either Location targeting (specific cities, regions, or countries) 
            or Proximity targeting (specific radius around coordinates).
            {targetingType && ` Current campaign is using ${targetingType} targeting.`}
          </p>
        </div>
      </div>

      <div className="bg-white p-6 rounded shadow">
        {location && (
          <div className="mb-4">
            <div className="flex justify-between items-start p-3 bg-gray-50 rounded-lg">
              <div>
                <div className="font-medium">
                  {location.type === 'LOCATION' 
                    ? `Location Target: ${location.resource_name}` 
                    : `Proximity Target: ${location.radius_miles} miles radius`}
                </div>
                {location.coordinates && (
                  <div className="text-sm text-gray-600">
                    Coordinates: {location.coordinates.lat.toFixed(4)}, {location.coordinates.lng.toFixed(4)}
                  </div>
                )}
              </div>
              <Button
                variant="ghost"
                size="sm"
                className="text-gray-500 hover:text-red-500"
                onClick={handleRemoveLocation}
              >
                <X className="h-4 w-4" />
              </Button>
            </div>
          </div>
        )}
        
        <AdLocationSelector 
          onLocationSelect={handleLocationSelect}
          currentTargetingType={targetingType}
          disabled={false}
        />

        {selectedLocation && (
          <div className="mt-4 flex justify-end">
            <Button
              onClick={handleAddLocation}
              className="bg-blue-500 hover:bg-blue-600 text-white"
            >
              Add Selected Location
            </Button>
          </div>
        )}
      </div>

      <div className="mt-6 p-4 bg-blue-100 border border-blue-200 rounded">
        <h4 className="font-semibold text-blue-800">Location Targeting Tips</h4>
        <ul className="mt-2 list-disc list-inside text-blue-700">
          <li>Location targeting: Best for targeting specific cities, regions, or countries</li>
          <li>Proximity targeting: Best for targeting specific radius around exact coordinates</li>
          <li>Only one target can be active at a time</li>
        </ul>
      </div>
    </div>
  );
};

export default LocationTargetingPage;
import React, { useEffect, useState } from 'react';
import { useAuth } from "@/utils/AuthProvider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { baseUrl } from '@/services/api-config';

function NavBar() {
  const { session, signIn, signOut, userStatus } = useAuth();
  const [preferredDomain, setPreferredDomain] = useState<string | null>(null);
  const [showSupportDialog, setShowSupportDialog] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchPreferences = async () => {
      if (session && location.pathname.endsWith('/manage-campaign') && userStatus && userStatus.is_onboarded) {
        // Check local storage first
        const storedDomain = localStorage.getItem('selectedDomain');
        if (storedDomain) {
          setPreferredDomain(storedDomain);
        } else {
          // If not in local storage, fetch from API
          try {
            const headers = {
              Authorization: `Bearer ${session.access_token}`,
              'Content-Type': 'application/json',
            };
            const response = await axios.get(`${baseUrl}/preferences`, { headers });

            if (!response.data.campaign_id) {

            } else if (!response.data.success) {
              console.log("Error");
              signOut()
            }

            const fetchedDomain = response.data.domain;
            setPreferredDomain(fetchedDomain);
            // Save to local storage
            localStorage.setItem('preferredDomain', fetchedDomain);
          } catch (error) {
            console.error('Error fetching user preferences:', error);
            toast.error('Failed to fetch user preferences');
          }
        }
      } else {
        // If not on manage-campaign page or not logged in, clear preferredDomain
        setPreferredDomain(null);
        localStorage.removeItem('preferredDomain');
      }
    };

    fetchPreferences();
  }, [session, location.pathname]); // Added location.pathname to dependencies

  return (
    <>
      <header className="bg-white border-b py-4 px-6 flex justify-between items-center w-full absolute top-0 z-50 shadow-sm">
        <div className="flex items-center">
          <Link to="/" className='flex items-center gap-4'>
            <img src="/aperture-thin.png" alt="Multiply" className="h-10" />
            <span className='text-textPrimary text-xl'>Multiply</span>
          </Link>
          {preferredDomain && (
            <span className="text-textPrimary ml-6 px-3 py-1 bg-gray-100 rounded-md text-sm">
              {preferredDomain}
            </span>
          )}
        </div>
        <div className="flex items-center">
          <button 
            onClick={() => setShowSupportDialog(true)} 
            className="text-textPrimary hover:underline mx-4"
          >
            Support
          </button>
          {session ? (
            <span onClick={signOut} className="text-textPrimary hover:underline cursor-pointer">Sign Out</span>
          ) : (
            <span onClick={signIn} className="text-textPrimary hover:underline cursor-pointer">Sign In</span>
          )}
        </div>
      </header>
      {showSupportDialog && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold">Need Support?</h2>
                <button 
                  onClick={() => setShowSupportDialog(false)}
                  className="text-gray-500 hover:text-gray-700"
                >
                  ✕
                </button>
              </div>
              <p className="text-gray-600">
                Please email support@trymultiply.com with any bugs or issues.
              </p>
              <button
                onClick={() => setShowSupportDialog(false)}
                className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
              >
                Got it
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default NavBar;
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Card, CardContent } from '@/components/ui/card';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { Label } from '@/components/ui/label';
import { Input } from '@/components/ui/input';
import { Slider } from '@/components/ui/slider';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';
import { baseUrl } from '@/services/api-config';

interface Coordinates {
  lat: number;
  lng: number;
}

interface LocationSuggestion {
  criterion_id: string;
  name: string;
  canonical_name: string;
  target_type: string;
  country_code: string;
}

interface AdLocationSelectorProps {
  disabled?: boolean;
  currentTargetingType?: 'LOCATION' | 'PROXIMITY' | null; // Add this prop
  onLocationSelect: (location: {
    type: 'LOCATION' | 'PROXIMITY';
    address_type: string;
    geo_target_constant?: string;
    radius_miles?: number;
    coordinates?: Coordinates;
    resource_name: string;
  }) => void;
}

const DEFAULT_CENTER = { lat: 40, lng: -100 };
const DEFAULT_ZOOM = 4;

const isValidLocationTargetType = (addressType: string): boolean => {
  const validTypes = ['COUNTRY', 'STATE', 'COUNTY', 'CITY', 'BOROUGH'];
  return validTypes.includes(addressType);
};

const AdLocationSelector: React.FC<AdLocationSelectorProps> = ({
  onLocationSelect,
  disabled,
  currentTargetingType
}) => {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);
  const [radius, setRadius] = useState<number>(10);
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [selectedPlace, setSelectedPlace] = useState<string>('');
  const [addressType, setAddressType] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [suggestions, setSuggestions] = useState<LocationSuggestion[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [targetingType, setTargetingType] = useState<'LOCATION' | 'PROXIMITY'>(
    currentTargetingType || 'LOCATION'
  );

  const searchInputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const mapListenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const debounceTimer = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (currentTargetingType) {
      setTargetingType(currentTargetingType);

      if (currentTargetingType === 'LOCATION' && autocompleteRef.current) {
        const input = searchInputRef.current;
        if (input) {
            google.maps.event.clearInstanceListeners(input);
            input.removeAttribute('placeholder');
        }
        autocompleteRef.current = null;
      }

    }
  }, [currentTargetingType]);

  useEffect(() => {
    return () => {
      mapListenersRef.current.forEach(listener => {
        google.maps.event.removeListener(listener);
      });
      mapListenersRef.current = [];
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      if (autocompleteRef.current) {
        const input = searchInputRef.current;
        if (input) {
          google.maps.event.clearInstanceListeners(input);
        }
        autocompleteRef.current = null;
      }
      mapListenersRef.current.forEach(listener => {
        google.maps.event.removeListener(listener);
      });
      mapListenersRef.current = [];
    };
  }, []);

  const fetchSuggestions = async (query: string) => {
    if (!query || query.length < 2) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `${baseUrl}/googleads/campaign/locations/suggest?query=${encodeURIComponent(query)}`
      );

      if (!response.ok) throw new Error('Failed to fetch suggestions');

      const data = await response.json();
      setSuggestions(data.suggestions);
      setShowSuggestions(true);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
      setSuggestions([]);
    }
  };

  const setupAutocomplete = useCallback((mapInstance: google.maps.Map) => {
    if (!searchInputRef.current || targetingType !== 'PROXIMITY') return;
  
    // Clear any existing listeners
    if (autocompleteRef.current) {
      const input = searchInputRef.current;
      if (input) {
        google.maps.event.clearInstanceListeners(input);
      }
      autocompleteRef.current = null;
    }
    mapListenersRef.current.forEach(listener => {
      google.maps.event.removeListener(listener);
    });
    mapListenersRef.current = [];
  
    const options: google.maps.places.AutocompleteOptions = {
      bounds: mapInstance.getBounds() || undefined,
      fields: ['address_components', 'geometry', 'formatted_address'],
    };
  
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInputRef.current,
      options
    );
    autocompleteRef.current = autocomplete;
  
    const placeChangedListener = autocomplete.addListener('place_changed', () => {
      setError('');
      const place = autocomplete.getPlace();
  
      if (!place.geometry?.location) return;
  
      const newCoordinates = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng()
      };
  
      setAddressType('PROXIMITY');
      setCoordinates(newCoordinates);
      setSelectedPlace(place.formatted_address || '');
  
      if (place.geometry.viewport) {
        mapInstance.fitBounds(place.geometry.viewport);
      } else {
        mapInstance.setCenter(place.geometry.location);
        mapInstance.setZoom(12);
      }
  
      onLocationSelect({
        type: 'PROXIMITY',
        address_type: 'PROXIMITY',
        radius_miles: radius,
        coordinates: newCoordinates,
        resource_name: place.formatted_address || `${newCoordinates.lat},${newCoordinates.lng}`
      });
    });
  
    mapListenersRef.current.push(placeChangedListener);
  
    const boundsListener = mapInstance.addListener('bounds_changed', () => {
      const bounds = mapInstance.getBounds();
      if (bounds) {
        autocomplete.setBounds(bounds);
      }
    });
    mapListenersRef.current.push(boundsListener);
  }, [targetingType, radius, onLocationSelect]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);

    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    if (targetingType === 'LOCATION') {
      setError('');
      debounceTimer.current = setTimeout(() => {
        fetchSuggestions(value);
      }, 300);
    }
  };

  const handleSuggestionSelect = async (suggestion: LocationSuggestion) => {
    setShowSuggestions(false);
    setInputValue(suggestion.canonical_name);
    setSelectedPlace(suggestion.canonical_name);
    setAddressType(suggestion.target_type);

    try {
      // Try to geocode the location to get coordinates for the map
      const geocoder = new google.maps.Geocoder();
      const result = await geocoder.geocode({ address: suggestion.canonical_name });

      if (result.results[0]?.geometry?.location) {
        const newCoordinates = {
          lat: result.results[0].geometry.location.lat(),
          lng: result.results[0].geometry.location.lng()
        };

        setCoordinates(newCoordinates);

        if (map) {
          if (result.results[0].geometry.viewport) {
            map.fitBounds(result.results[0].geometry.viewport);
          } else {
            map.setCenter(newCoordinates);
            map.setZoom(12);
          }
        }

        onLocationSelect({
          type: 'LOCATION',
          address_type: suggestion.target_type,
          geo_target_constant: suggestion.criterion_id,
          coordinates: newCoordinates,
          resource_name: suggestion.canonical_name
        });
      } else {
        onLocationSelect({
          type: 'LOCATION',
          address_type: suggestion.target_type,
          geo_target_constant: suggestion.criterion_id,
          resource_name: suggestion.canonical_name
        });
      }
    } catch (error) {
      console.error('Geocoding error:', error);
      // Still send the location data even if geocoding fails
      onLocationSelect({
        type: 'LOCATION',
        address_type: suggestion.target_type,
        geo_target_constant: suggestion.criterion_id,
        resource_name: suggestion.canonical_name
      });
    }
  };

  const onMapLoad = useCallback((mapDiv: google.maps.Map) => {
    setMap(mapDiv);
    mapDiv.setCenter(DEFAULT_CENTER);
    mapDiv.setZoom(DEFAULT_ZOOM);
    setupAutocomplete(mapDiv);
  }, [setupAutocomplete]);

  const handleTargetingTypeChange = useCallback((value: 'LOCATION' | 'PROXIMITY') => {
    setTargetingType(value);
    setError('');
    setInputValue('');
    setSelectedPlace('');
    setAddressType('');
    setSuggestions([]);
    setShowSuggestions(false);
    setCoordinates(null);

    if (autocompleteRef.current) {
      const input = searchInputRef.current;
      if (input) {
        google.maps.event.clearInstanceListeners(input);
        input.removeAttribute('placeholder');
      }
      autocompleteRef.current = null;
    }

    if (map) {
      map.setCenter(DEFAULT_CENTER);
      map.setZoom(DEFAULT_ZOOM);

      if (value === 'PROXIMITY') {
        requestAnimationFrame(() => setupAutocomplete(map));
      }
    }
  }, [map, setupAutocomplete]);

  useEffect(() => {
    if (map) {
      setupAutocomplete(map);
    }
  }, [targetingType, setupAutocomplete, map]);

  useEffect(() => {
    if (targetingType === 'PROXIMITY' && coordinates) {
      onLocationSelect({
        type: 'PROXIMITY',
        address_type: 'PROXIMITY',
        radius_miles: radius,
        coordinates: coordinates,
        resource_name: selectedPlace || `${coordinates.lat},${coordinates.lng}`
      });
    }
  }, [radius, coordinates, targetingType, selectedPlace, onLocationSelect]);

  const mapOptions: google.maps.MapOptions = {
    streetViewControl: false,
    mapTypeControl: false,
    fullscreenControl: false,
    styles: [
      {
        featureType: 'water',
        elementType: 'geometry',
        stylers: [{ color: '#e9f5f9' }]
      },
      {
        featureType: 'landscape',
        elementType: 'geometry',
        stylers: [{ color: '#ffffff' }]
      },
      {
        featureType: 'administrative.province',
        elementType: 'geometry.stroke',
        stylers: [{ color: '#8080ff' }, { weight: 1 }]
      }
    ]
  };

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardContent className="p-6">
        <h2 className="text-xl font-semibold mb-2">Where do you want to advertise?</h2>
        <p className="text-gray-600 mb-4">
          {targetingType === 'LOCATION'
            ? "Enter a city, borough, state, or country in the US or Europe"
            : "Enter a specific address in the US or Europe for radius targeting"}
        </p>

        <RadioGroup
          value={targetingType}
          onValueChange={handleTargetingTypeChange}
        >
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="LOCATION" id="location" />
              <Label htmlFor="location">Target specific locations</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="PROXIMITY" id="proximity" />
              <Label htmlFor="proximity">Target by radius</Label>
            </div>
          </div>
        </RadioGroup>

        <div className="mt-4 relative">
          <Input
            ref={searchInputRef}
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            placeholder={targetingType === 'LOCATION'
              ? "Enter a city, borough, state, or country"
              : "Enter an address for radius targeting"
            }
            className="w-full mb-2"
            disabled={disabled}
          />

          {targetingType === 'LOCATION' && showSuggestions && suggestions.length > 0 && (
            <div className="absolute z-50 w-full bg-white shadow-lg rounded-md border mt-1 max-h-[300px] overflow-y-auto">
              <div className="p-1">
                {suggestions.length === 0 ? (
                  <div className="px-2 py-3 text-sm text-gray-500 text-center">
                    No locations found.
                  </div>
                ) : (
                  suggestions.map((suggestion) => (
                    <div
                      key={suggestion.criterion_id}
                      onClick={() => handleSuggestionSelect(suggestion)}
                      className="flex flex-col px-2 py-2 hover:bg-gray-100 cursor-pointer rounded-sm"
                    >
                      <span className="font-medium">{suggestion.canonical_name}</span>
                      <span className="text-sm text-gray-500">
                        {suggestion.target_type.charAt(0) + suggestion.target_type.slice(1).toLowerCase()}
                      </span>
                    </div>
                  ))
                )}
              </div>
            </div>
          )}

          {error && (
            <div className="text-sm text-red-500 mt-1">
              {error}
            </div>
          )}
          {addressType && !error && (
            <div className="text-sm text-gray-600 mt-1">
              Selected location type: {addressType.replace(/_/g, ' ').toLowerCase()}
            </div>
          )}
        </div>

        <div className="mt-4">
          <GoogleMap
            onLoad={onMapLoad}
            center={coordinates || DEFAULT_CENTER}
            zoom={coordinates ? 12 : DEFAULT_ZOOM}
            options={mapOptions}
            mapContainerStyle={{ height: '400px', width: '100%' }}
          >
            {coordinates && (
              <>
                <Marker position={coordinates} />
                {targetingType === 'PROXIMITY' && (
                  <Circle
                    center={coordinates}
                    radius={radius * 1609.34}  // Convert miles to meters
                    options={{
                      fillColor: '#4285f4',
                      fillOpacity: 0.3,
                      strokeColor: '#4285f4',
                      strokeOpacity: 0.8,
                      strokeWeight: 2,
                    }}
                  />
                )}
              </>
            )}
          </GoogleMap>

          {targetingType === 'PROXIMITY' && (
            <div className="mt-4">
              <Label htmlFor="radius">Radius (miles)</Label>
              <Slider
                id="radius"
                min={1}
                max={50}
                step={1}
                value={[radius]}
                onValueChange={(value) => setRadius(value[0])}
                className="mt-2"
              />
              <div className="text-sm text-gray-600 mt-1">
                Current radius: {radius} miles
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
};

export default AdLocationSelector;
import { Session } from "@supabase/supabase-js";
import { useContext, useState, useEffect, createContext, useCallback, useRef } from "react";
import supabase from "../utils/supabase";
import { toast } from 'react-toastify';
import axios from "axios";
import { baseUrl } from "@/services/api-config";

const PRODUCTION_URL = 'https://app.trymultiply.com';
const DEVELOPMENT_URL = 'http://localhost:8000';

interface UserStatus {
  is_onboarded: boolean;
  account_linked: boolean;
  oauth2_linked: boolean;
}

interface AuthContextType {
  session: Session | null | undefined;
  token: string | null | undefined;
  signInForOnboarding: () => Promise<void>;
  signIn: () => Promise<void>;
  signOut: () => Promise<void>;
  refreshSession: () => Promise<Session | null>;
  isAuthenticated: boolean;
  userStatus?: UserStatus;
  getUserStatus: () => Promise<UserStatus | undefined>;
}

const AuthContext = createContext<AuthContextType>({
  session: null,
  token: null,
  signInForOnboarding: async () => { },
  signIn: async () => { },
  signOut: async () => { },
  refreshSession: async () => null,
  isAuthenticated: false,
  userStatus: undefined,
  getUserStatus: async () => undefined,
});

const getBaseUrl = () => {
  const env = process.env.NODE_ENV;
  console.log('Current environment:', env);
  return env === 'production' ? PRODUCTION_URL : DEVELOPMENT_URL;
}

const buildRedirectUrl = () => {
  const BASE_URL = 'https://app.trymultiply.com'; 
  const finalDestination = `${BASE_URL}/onboarding/link-google-account?step=loading`;
  const redirectUrl = `${BASE_URL}/account/auth/callback?final_redirect=${encodeURIComponent(finalDestination)}`;
  return redirectUrl;
};

const getRedirectUrl = (path: string) => {
  const baseUrl = getBaseUrl();
  const encodedRedirect = encodeURIComponent(`${baseUrl}/${path}`);
  const redirectUrl = `https://app.trymultiply.com/account/auth/callback?final_redirect=${encodedRedirect}`; 
  return redirectUrl;
};

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [session, setSession] = useState<Session | null>();
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userStatus, setUserStatus] = useState<UserStatus>();
  
  const isCheckingStatus = useRef(false);

  const getUserStatus = useCallback(async (): Promise<UserStatus | undefined> => {
    if (!session?.access_token || isCheckingStatus.current) {
      return undefined;
    }
  
    try {
      isCheckingStatus.current = true;
      const headers = {
        Authorization: `Bearer ${session.access_token}`,
        'Content-Type': 'application/json',
      };
  
      const resp = await axios.get(`${baseUrl}/isOnboarded`, { headers });
      return resp.data;
    } catch (error) {
      console.error('Error fetching user status:', error);
      return undefined;
    } finally {
      isCheckingStatus.current = false;
    }
  }, []);

  const handleSignOut = useCallback(async (message?: string) => {
    try {
      await supabase.auth.signOut();
      setSession(null);
      setIsAuthenticated(false);
      setUserStatus(undefined);
      if (message) {
        toast.error(message);
      }
    } catch (error) {
      console.error('Sign out error:', error);
    }
  }, []);

  const validateSession = useCallback((currentSession: Session | null): boolean => {
    if (!currentSession?.access_token) {
      return false;
    }

    try {
      const [header, payload, signature] = currentSession.access_token.split('.');
      if (!header || !payload || !signature) {
        return false;
      }

      const tokenData = JSON.parse(atob(payload));
      const expirationTime = tokenData.exp * 1000;
      const currentTime = Date.now();

      if (currentTime >= expirationTime || !tokenData.sub) {
        return false;
      }

      return true;
    } catch (error) {
      console.error('Token validation error:', error);
      return false;
    }
  }, []);

  const refreshSession = useCallback(async (): Promise<Session | null> => {
    try {
      const { data, error } = await supabase.auth.refreshSession();

      if (error) {
        await handleSignOut('Your session has expired. Please sign in again.');
        return null;
      }

      const newSession = data.session;
      if (!validateSession(newSession)) {
        await handleSignOut('Invalid session. Please sign in again.');
        return null;
      }

      setSession(newSession);
      setIsAuthenticated(!!newSession);
      return newSession;
    } catch (error) {
      console.error('Session refresh error:', error);
      await handleSignOut('Unable to refresh session. Please sign in again.');
      return null;
    }
  }, [handleSignOut, validateSession]);

  useEffect(() => {
    const init = async () => {
      try {
        const { data: { session: currentSession }, error } = await supabase.auth.getSession();
        
        if (!error && currentSession && validateSession(currentSession)) {
          setSession(currentSession);
          setIsAuthenticated(true);
          
          const status = await getUserStatus();
          if (status) {
            setUserStatus(status);
          }
        }
      } catch (error) {
        console.error('Initialization error:', error);
      } finally {
        setLoading(false);
      }
    };
  
    init();
  }, [validateSession, getUserStatus]);

  useEffect(() => {
    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, currentSession) => {
        if (loading) return;

        if (!currentSession || event === 'SIGNED_OUT') {
          setSession(null);
          setIsAuthenticated(false);
          setUserStatus(undefined);
          return;
        }

        if (!validateSession(currentSession)) {
          handleSignOut('Session validation failed. Please sign in again.');
          return;
        }

        setSession(currentSession);
        setIsAuthenticated(true);
        
        const status = await getUserStatus();
        if (status) {
          setUserStatus(status);
        }
      }
    );
  
    return () => {
      listener?.subscription.unsubscribe();
    };
  }, [validateSession, handleSignOut, getUserStatus, loading]);

  const signInForOnboarding = async () => {
    const redirectTo = buildRedirectUrl();

    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: redirectTo,
          scopes: 'https://www.googleapis.com/auth/adwords',
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const signIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: getRedirectUrl('manage-campaign'),
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      console.error('Sign in error:', error);
      throw error;
    }
  };

  const value = {
    session,
    token: session?.access_token ?? null,
    signIn,
    signOut: () => handleSignOut(),
    signInForOnboarding,
    isAuthenticated,
    refreshSession,
    userStatus,
    getUserStatus
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
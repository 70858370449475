import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import SetBudgetPage from '../pages/SetBudgetPage';
import LocationTargetingPage from '../pages/LocationTargetingPage';
import GoogleAdPreview from '../pages/AdPreviewPageV2';
import GoogleAdsConnection from '@/pages/GoogleAuthFlowContainer';
import ConversionProfile from '@/pages/ConversionProfile';
import { StepStatus } from '@/utils/Interfaces';
import { DOMAIN_KEY } from '@/types/constants';

const OnboardingContainer: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  const steps = [
    GoogleAdPreview,
    LocationTargetingPage,
    SetBudgetPage,
    GoogleAdsConnection,
    ConversionProfile
  ];
  
  const [stepStatus, setStepStatus] = useState<StepStatus>({ isComplete: false });
  const [domain, setDomain] = useState<string>('')
  const [step, setStep] = useState(0);

  useEffect(() => {
    const domain = localStorage.getItem(DOMAIN_KEY);

    if (!domain) {
      navigate('/')
    }

    setDomain(domain!);
    const params = new URLSearchParams(location.search);
    const stepParam = params.get('step');
    if (stepParam !== null) {
      const newStep = parseInt(stepParam, 10);
      if (!isNaN(newStep) && newStep >= 0 && newStep < steps.length) {
        setStep(newStep);
      }
    } else {
      // If no step is provided in the URL, set it to 0 and update the URL
      setStep(0);
      navigate(`/onboarding?step=0`, { replace: true });
    }

  }, [location, domain, navigate, steps.length]);

  const handleStepComplete = (status: StepStatus) => {
    setStepStatus(status);
  };

  const isLastStep = () => step === steps.length - 1;

  const isNextButtonDisabled = () => {
    return !stepStatus.isComplete;
  };

  const nextStep = () => {
    if (isNextButtonDisabled()) return;
    
    const newStep = Math.min(step + 1, steps.length - 1);
    setStep(newStep);
    setStepStatus({ isComplete: false }); // Reset completion status for new step
    navigate(`/onboarding?step=${newStep}`);
  };

  const prevStep = () => {
    const newStep = Math.max(step - 1, 0);
    setStep(newStep);
    navigate(`/onboarding?step=${newStep}`);
  };

  const Page = steps[step];

  return (
    <div className="max-w-4xl mx-auto p-6">
      <Page url={domain} onStepComplete={handleStepComplete} />
      <div className="flex justify-between mt-8">
        {step > 0 ? (
          <button
            onClick={prevStep}
            className="px-4 py-2 rounded bg-gray-300 text-gray-500 hover:bg-gray-400"
          >
            Previous
          </button>
        ) : (
          <div />
        )}
        
        <div className="flex items-center gap-4">
          {!stepStatus.isComplete && stepStatus.message && (
            <span className="text-red-500 text-sm">{stepStatus.message}</span>
          )}
          {!isLastStep() && (
            <button
              onClick={nextStep}
              disabled={isNextButtonDisabled()}
              className={`px-4 py-2 rounded text-white ${
                isNextButtonDisabled()
                  ? 'bg-gray-400 cursor-not-allowed'
                  : 'bg-primary hover:bg-primaryDark'
              }`}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default OnboardingContainer;
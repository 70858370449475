import React, { useEffect, useState, useCallback } from 'react';
import { cn } from "@/utils/utils";
import ManageKeywords from './ManageKeywords';
import GoogleAdsBudgetPage from './ManageBudgetPage';
import GoogleAdsLocationPage from './ManageLocationsPage';
import SettingsPage from './SettingsPage';
import { useAuth } from '@/utils/AuthProvider'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import ManageAdContent from './ManageAdContent';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { Card } from '@/components/ui/card';
import AnalyticsDashboard from '@/components/AnalyticsDashboard';

const navigationItems = [
  { id: 'overview', label: 'Overview', icon: '📊' },
  { id: 'budget', label: 'Budget', icon: '💰' },
  { id: 'adContent', label: 'Ad content', icon: '✏️' },
  { id: 'keywords', label: 'Keywords', icon: '🔑' },
  { id: 'locationTargeting', label: 'Location targeting', icon: '📍' },
  { id: 'settings', label: 'Settings', icon: '⚙️' },
];

interface AnalyticsData {
  impressions: number | null;
  clicks: number | null;
  ctr: number | null;
  cost_micros: number | null;
  all_conversions: number | null;
  status: 'ACTIVE' | 'PAUSED' | 'UNKNOWN';
}


const ManageCampaignPage: React.FC = () => {
  const location = useLocation();
  const { session, refreshSession, userStatus } = useAuth();
  const navigate = useNavigate();
  const [selectedNav, setSelectedNav] = useState('overview');
  const [campaignId, setCampaignId] = useState<string | null>(location.state?.campaignId || null);
  const [positiveKeywords, setPositiveKeywords] = useState<string[]>([]);
  const [negativeKeywords, setNegativeKeywords] = useState<string[]>([]);
  const [campaignStatus, setCampaignStatus] = useState<'ACTIVE' | 'PAUSED' | 'UNKNOWN'>('UNKNOWN');
  const [headlines, setHeadlines] = useState<string[]>([]);
  const [domain, setDomain] = useState<string>(localStorage.getItem('domain') ?? '')
  const [descriptions, setDescriptions] = useState<string[]>([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  const [analyticsData, setAnalyticsdata] = useState<AnalyticsData>({
    impressions: null,
    clicks: null,
    ctr: null,
    cost_micros: null,
    all_conversions: null,
    status: 'UNKNOWN'
  });

  interface AdPreviewResponse {
    success: boolean;
    url: string;
    headlines: string[],
    descriptions: string[]
  }

  const fetchPreviewData = useCallback(async () => {
    if (!campaignId) {
      console.log("No campaign ID set");
      return;
    }
    if (!session || !session.access_token) {
      console.log("missing session data");
      await refreshSession();
    }
    if (!session || !session.access_token) {
      console.log(`Missing session after refresh`);
      return;
    }

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const responseData = await axios.get<AdPreviewResponse>(
        `${baseUrl}/googleads/campaign/${campaignId}/preview`,
        { headers }
      );
      const newHeadlines: string[] = [];
      const newDescriptions: string[] = [];


      for (const item of responseData.data.headlines) {
        newHeadlines.push(item);
      }
      for (const item of responseData.data.descriptions) {
        newDescriptions.push(item);
      }
      
      console.log(`New headlines: [${newHeadlines}]`);
      console.log(`New descriptions: [${newDescriptions}]`);
      // Fix 2: Set state with new arrays instead of using closure values
      setDomain(responseData.data.url);
      setHeadlines(newHeadlines);
      setDescriptions(newDescriptions);
    } catch (error) {
      console.error('Error fetching preview data:', error);
      toast.error('Failed to fetch preview data');
    }
  }, [campaignId, session, refreshSession]);

  const fetchCampaignStatus = useCallback(async () => {
    if (!campaignId) {
      return;
    }
    if (!session?.access_token) {
      await refreshSession();
    }
    if (!session?.access_token) {
      toast.error("Network error please contact support");
      return;
    }
  
    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };
  
    try {
      const response = await axios.get(
        `${baseUrl}/googleads/campaign/${campaignId}/status`,
        { headers }
      );

      setCampaignId(response.data.campaign_id); 
      setCampaignStatus(response.data.status);
    } catch (error) {
      console.error('Error fetching campaign status:', error);
      toast.error('Failed to fetch campaign status');
    }
  }, [campaignId, session, refreshSession]);

  const fetchUserPreferences = useCallback(async () => {
    if (!session?.access_token) return;

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(`${baseUrl}/preferences`, { headers });
      setCampaignId(response.data.campaign_id);
    } catch (error) {
      console.error('Error fetching user preferences:', error);
      toast.error('Failed to fetch user preferences');
    }
  }, [session?.access_token]);

  const fetchKeywords = useCallback(async () => {
    if (!session?.access_token || !campaignId) return;

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(`${baseUrl}/googleads/campaign/${campaignId}/keywords`, { headers });
      setPositiveKeywords(response.data.positive_keywords || []);
      setNegativeKeywords(response.data.blocked_keywords || []);
    } catch (error) {
      console.error('Error fetching keywords:', error);
      toast.error('Failed to fetch keywords');
    }
  }, [session?.access_token, campaignId]);

  const fetchAnalytics = useCallback(async () => {
    if (!campaignId) {
      return;
    }
    if (!session?.access_token) {
      await refreshSession();
    }
    if (!session?.access_token) {
      toast.error("Network error please contact support");
      return;
    }

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.get(
        `${baseUrl}/googleads/campaign/${campaignId}/homepage`,
        { headers }
      );

      return response.data;
    } catch (error) {
      console.error('Error fetching analytics:', error);
      toast.error('Failed to fetch analytics data');
      return null;
    }
  }, [campaignId, session, refreshSession]);

  useEffect(() => {
    const initializePage = async () => {
      // First check for valid session
      if (!session) {
        navigate('/');
        return;
      }
  
      // Ensure valid session token
      if (!session.access_token) {
        const newSession = await refreshSession();
        if (!newSession?.access_token) {
          navigate('/');
          return;
        }
      }
  
      try {
        // If we don't have a campaignId from location state, try to get it from preferences
        if (userStatus && userStatus.is_onboarded && !campaignId) {
          try {
            await fetchUserPreferences();
            // If we still don't have a campaignId after preferences call
            if (!campaignId) {
              toast.error('No campaign found. Please create a campaign first.');
              navigate('/create-campaign'); // Or wherever your fallback route is
              return;
            }
          } catch (error) {
            console.error('Failed to fetch preferences:', error);
            toast.error('Could not load campaign information');
            navigate('/create-campaign'); // Or appropriate fallback route
            return;
          }
        }
  
        // Only proceed with data fetching if we have a valid campaignId
        if (campaignId) {
          // Run these in parallel but with error handling
          const results = await Promise.allSettled([
            fetchKeywords(),
            fetchPreviewData(),
            fetchCampaignStatus()
          ]);
          
          // Check if all critical operations failed
          const allFailed = results.every(result => result.status === 'rejected');
          if (allFailed) {
            toast.error('Failed to load campaign data. Please try again.');
            navigate('/'); // Or appropriate error page
            return;
          }
  
          // Log individual failures but continue if some succeeded
          results.forEach((result, index) => {
            if (result.status === 'rejected') {
              console.error(`Failed to fetch data ${index}:`, result.reason);
            }
          });
        }
  
        // Only fetch analytics if we're on overview tab
        if (selectedNav === 'overview') {
          const data = await fetchAnalytics();
          if (data) {
            setAnalyticsdata({
              impressions: data.impressions,
              clicks: data.clicks,
              ctr: data.ctr,
              cost_micros: data.cost_micros,
              all_conversions: data.all_conversions,
              status: data.status
            });
          }
        }
      } catch (error) {
        console.error('Error initializing page:', error);
        toast.error('Failed to initialize page');
        navigate('/error'); // Or appropriate error page
      }
    };
  
    initializePage();
  }, [
    session,
    navigate,
    campaignId,
    selectedNav,
    fetchUserPreferences,
    fetchKeywords,
    fetchPreviewData,
    fetchAnalytics,
    fetchCampaignStatus,
    refreshSession // Add refreshSession to dependencies
  ]);

  const handlePrevAd = () => {
    setCurrentAdIndex((prev) => 
      prev > 0 ? prev - 1 : (headlines.length || 1) - 1
    );
  };

  const handleNextAd = () => {
    setCurrentAdIndex((prev) => 
      prev < (headlines.length || 1) - 1 ? prev + 1 : 0
    );
  };

  const toggleCampaignStatus = async () => {
    if (!campaignId || !session?.access_token) {
      toast.error('Unable to update campaign status. Please try again.');
      return;
    }

    const newStatus = campaignStatus === 'ACTIVE' ? 'PAUSED' : 'ACTIVE';

    const headers = {
      Authorization: `Bearer ${session.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${baseUrl}/googleads/campaign/status`,
        { 
          campaign_id: campaignId,
          status: newStatus 
        },
        { headers }
      );

      if (response.status === 200) {
        setCampaignStatus(newStatus);
        toast.success(`Campaign ${newStatus.toLowerCase()} successfully`);
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating campaign status:', error);
      if (axios.isAxiosError(error)) {
        toast.error(`Failed to update campaign status: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error('An unexpected error occurred while updating campaign status');
      }
    }
  };

  // In your ManageCampaignPage component:
  const renderAnalyticsContent = () => {
    return (
      <div className="space-y-6">
        <AnalyticsDashboard 
          data={{
            ...analyticsData,
            status: campaignStatus,
          }}
          onStatusChange={toggleCampaignStatus}
          adPreviews={{
            headlines,
            descriptions,
            domain,
            currentIndex: currentAdIndex,
            onNext: handleNextAd,
            onPrevious: handlePrevAd
          }}
        />
        
        <h2 className="text-xl font-semibold mb-4">Campaign overview</h2>
        <div className="grid grid-cols-2 gap-6">
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-2">Ad previews</h3>
            <div className="border p-4 rounded">
              {(!headlines.length || !descriptions.length || !domain) ? (
                <p className="text-gray-500">No ad creative found</p>
              ) : (
                <div className="space-y-4">
                  <p className="text-sm text-gray-500">Ad {currentAdIndex + 1} of {headlines.length}</p>
                  <div>
                    <p className="text-sm text-green-600">{domain}</p>
                    <h4 className="text-blue-600 font-medium">
                      {headlines[currentAdIndex]}
                    </h4>
                    <p className="text-sm text-gray-600">
                      {descriptions[currentAdIndex]}
                    </p>
                  </div>
                  <div className="flex justify-between mt-4">
                    <button
                      onClick={handlePrevAd}
                      className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
                      disabled={headlines.length <= 1}
                    >
                      Previous
                    </button>
                    <button
                      onClick={handleNextAd}
                      className="px-3 py-1 text-sm border rounded hover:bg-gray-50 transition-colors"
                      disabled={headlines.length <= 1}
                    >
                      Next
                    </button>
                  </div>
                </div>
              )}
            </div>
          </Card>
  
          <Card className="p-6">
            <h3 className="text-lg font-semibold mb-2">Ad search results</h3>
            <div className="border p-4 rounded h-full flex items-center justify-center">
              <div className="text-center">
                <p className="text-gray-500 mb-2">No search terms data available yet</p>
                <p className="text-sm text-gray-400">Search terms will appear here once your ads start receiving impressions</p>
              </div>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const handleUpdateKeywords = async (positive: string[], negative: string[]) => {
    if (!campaignId) {
      toast.error('Unable to update keywords. Please try again.');
      return;
    } else if (!session?.access_token) {
      await refreshSession();
    }

    if (!session?.access_token) {
      toast.error("Network error please contact support");
      return;
    }

    const headers = {
      Authorization: `Bearer ${session!.access_token}`,
      'Content-Type': 'application/json',
    };

    try {
      const response = await axios.post(
        `${baseUrl}/googleads/campaign/keywords`,
        {
          campaign_id: campaignId,
          positive_keywords: positive,
          blocked_keywords: negative
        },
        { headers }
      );

      if (response.status === 200) {
        setPositiveKeywords(positive);
        setNegativeKeywords(negative);
        toast.success('Keywords updated successfully');
      } else {
        throw new Error('Unexpected response status');
      }
    } catch (error) {
      console.error('Error updating keywords:', error);
      if (axios.isAxiosError(error)) {
        console.error('Axios error details:', error.response?.data);
        toast.error(`Failed to update keywords: ${error.response?.data?.message || error.message}`);
      } else {
        toast.error('An unexpected error occurred while updating keywords');
      }
    }
  };

  return (
    <div className="flex h-full">
      {/* Navigation Panel */}
      <nav className="w-64 bg-white border-r">
        <ul className="py-4">
          {navigationItems.map((item) => (
            <li key={item.id}>
              <button
                onClick={() => setSelectedNav(item.id)}
                className={cn(
                  "w-full text-left px-4 py-2 flex items-center space-x-2",
                  selectedNav === item.id ? "bg-blue-100 text-blue-600" : "hover:bg-gray-100"
                )}
              >
                <span>{item.icon}</span>
                <span>{item.label}</span>
              </button>
            </li>
          ))}
        </ul>
      </nav>

      {/* Main Content */}
      <div className="flex-1 p-8">
        {selectedNav === 'keywords' ? (
          <ManageKeywords
            positiveKeywords={positiveKeywords}
            negativeKeywords={negativeKeywords}
            onUpdateKeywords={handleUpdateKeywords}
          />
        ) : selectedNav === 'adContent' ? (
          <ManageAdContent
            campaignId={campaignId ?? ''}
          />
        ) : selectedNav === 'budget' ? (
          <GoogleAdsBudgetPage campaignId={campaignId} />
        ) : selectedNav === 'locationTargeting' ? (
          <GoogleAdsLocationPage campaignId={campaignId} />
        ) : selectedNav === 'settings' ? (
          <SettingsPage />
        ) : (
          renderAnalyticsContent()
        )}
      </div>
    </div>
  );
};

export default ManageCampaignPage;
import { VerticalDetails } from '@/pages/VerticalLandingPage';
// Usage examples

// Personal Injury Law content
export const personalInjuryDetails: VerticalDetails = {
  industry: 'Personal Injury Law Firms',
  title: 'Personal Injury Law',
  subheadline:
    'AI-powered ad campaigns that bring more high-value clients to your law firm. Outperform competitors and maximize your ROI.',
  features: [
    {
      title: 'AI-Powered Targeting',
      description:
        'Our advanced AI analyzes search trends to target high-intent potential clients.',
    },
    {
      title: 'Compliance Assured',
      description:
        'Stay compliant with legal advertising regulations while maximizing your reach.',
    },
    {
      title: 'ROI Optimization',
      description:
        'Continuously optimize your campaigns for the best return on your ad spend.',
    },
  ],
  testimonial: {
    quote:
      "This tool transformed our online presence. We've seen a 300% increase in qualified leads since implementing their AI-driven strategies.",
    author: 'John Doe, Senior Partner at Doe & Associates',
  },
  ctaText: 'Start Your Free Trial',
};

// Dentist Office content
export const dentistOfficeDetails: VerticalDetails = {
  industry: 'Dental Practices',
  title: 'Dental Practices',
  subheadline:
    'Grow your dental practice with smart, targeted advertising that brings new patients through your doors.',
  features: [
    {
      title: 'Local Patient Targeting',
      description:
        'Our AI focuses on local search patterns to attract patients in your area.',
    },
    {
      title: 'Service-Specific Campaigns',
      description:
        'Create tailored ads for each of your dental services to maximize relevance.',
    },
    {
      title: 'Patient Lifetime Value Optimization',
      description:
        'Attract patients likely to become long-term, high-value clients for your practice.',
    },
  ],
  testimonial: {
    quote:
      "Since using this AI tool, our new patient appointments have increased by 50%. It's been a game-changer for our practice.",
    author: 'Dr. Sarah Smith, Smile Bright Dental Clinic',
  },
  ctaText: 'Book a Demo',
};

import { dentistOfficeDetails, personalInjuryDetails } from '@/utils/config';
import React, { useState } from 'react';

interface Feature {
  title: string;
  description: string;
}

export interface VerticalDetails {
  title: string;
  industry: string;
  subheadline: string;
  features: Feature[];
  testimonial: {
    quote: string;
    author: string;
  };
  ctaText: string;
}

interface VerticalLandingPageProps {
  verticalDetails: VerticalDetails;
}

const VerticalLandingPage: React.FC<VerticalLandingPageProps> = ({ verticalDetails }) => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Submitted email:', email);
    // Handle form submission logic here
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <section className="px-4 py-20 md:py-32 max-w-6xl mx-auto">
        <div className="text-center mb-12">
          <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight">
            Dominate Google Ads for <span className="text-blue-600">{verticalDetails.title}</span>
          </h1>
          <p className="text-xl md:text-2xl text-gray-600 max-w-3xl mx-auto">
            {verticalDetails.subheadline}
          </p>
        </div>
        <form onSubmit={handleSubmit} className="max-w-md mx-auto">
          <div className="flex flex-col md:flex-row gap-4">
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="flex-grow px-4 py-3 rounded-lg text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Get Started
            </button>
          </div>
        </form>
      </section>

      {/* Features Section */}
      <section className="bg-white py-20">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-12">Why Top {verticalDetails.industry} Choose Us</h2>
          <div className="grid md:grid-cols-3 gap-8">
            {verticalDetails.features.map((feature, index) => (
              <div key={index} className="bg-gray-50 p-6 rounded-lg">
                <h3 className="text-xl font-semibold mb-3">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Testimonial Section */}
      <section className="py-20 bg-gray-900 text-white">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <blockquote className="text-2xl md:text-3xl font-light italic mb-8">
            "{verticalDetails.testimonial.quote}"
          </blockquote>
          <p className="font-semibold">{verticalDetails.testimonial.author}</p>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-20">
        <div className="max-w-4xl mx-auto px-4 text-center">
          <h2 className="text-4xl font-bold mb-6">Ready to Grow Your {verticalDetails.industry}?</h2>
          <p className="text-xl text-gray-600 mb-8">
            Join the leading {verticalDetails.industry} using AI to dominate Google Ads.
          </p>
          <button className="bg-blue-600 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-blue-700 transition duration-300">
            {verticalDetails.ctaText}
          </button>
        </div>
      </section>
    </div>
  );
};

export const PersonalInjuryLandingPage = () => {
  return <VerticalLandingPage verticalDetails={personalInjuryDetails} />
};

export const DentistOfficeLandingPage = () => {
  return <VerticalLandingPage verticalDetails={dentistOfficeDetails} />
};
import React, { useEffect, useState } from 'react';
import { Card, CardContent, CardHeader } from '@/components/ui/card';
import { Button } from '@/components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { motion } from 'framer-motion';
import axios from 'axios';
import { baseUrl } from '@/services/api-config';
import { toast } from 'react-toastify';
import { StepStatus } from '@/utils/Interfaces';
import { useSearchParams } from 'react-router-dom';

interface PreviewData {
  businessName: string;
  adHeadings: string[];
  adDescriptions: string[];
  keywords: string[];
}

interface GoogleAdPreviewProps {
  url?: string;
  onStepComplete?: (status: StepStatus) => void;
}

const GoogleAdPreview: React.FC<GoogleAdPreviewProps> = ({ url, onStepComplete }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [previewData, setPreviewData] = useState<PreviewData | null>(null);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    onStepComplete?.({
      isComplete: !!previewData,
      message: !previewData ? 'Please wait while we analyze your website' : undefined
    });
  }, [previewData, onStepComplete]);

  const fetchPreviewData = async () => {
    if (!url) {
      toast.error('No URL provided');
      setIsLoading(false);
      return;
    }

    try {
      const data = await axios.get(`${baseUrl}/getSiteData`, {
        params: { url },
        headers: {
          'accept': 'application/json'
        }
      });

      const { descriptions, headlines, keywords, businessName, suggested_budget, domain } = data.data;
      setPreviewData({
        businessName,
        adHeadings: headlines,
        adDescriptions: descriptions,
        keywords: keywords,
      });

      localStorage.setItem(`${domain}_BUDGET_SUGGESTION`, suggested_budget);
    } catch (error) {
      toast.error(`Error fetching keyword suggestions: [${error}]`);
      setIsLoading(false);
    } finally {
      localStorage.setItem('domain', url);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const stepVal = searchParams.get('step')
    if (url && stepVal && Number(stepVal) === 0) {
      setIsLoading(true);
      fetchPreviewData();
    }
  }, [url]);

  const handlePrevAd = () => {
    setCurrentAdIndex((prev) => 
      prev > 0 ? prev - 1 : (previewData?.adHeadings.length || 1) - 1
    );
  };

  const handleNextAd = () => {
    setCurrentAdIndex((prev) => 
      prev < (previewData?.adHeadings.length || 1) - 1 ? prev + 1 : 0
    );
  };

  if (isLoading || !previewData) {
    return (
      <div className="flex items-center justify-center h-screen">
        <motion.div
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin"></div>
        </motion.div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-4">Welcome to Multiply, {previewData.businessName}</h1>
      <p className="text-gray-600 mb-6">
        Multiply simplifies your advertising journey by automatically creating, managing, and
        optimizing your Google Ad campaigns. Let's take a look at what Multiply will do for you.
      </p>

      <div className="flex gap-6">
        <div className="flex-grow">
          <Card className="mb-6">
            <CardHeader>
              <h2 className="text-xl font-semibold">Preview your Google ads</h2>
              <p className="text-sm text-gray-500">
                Here's a sneak peak of an ad that Multiply might run. Multiply analyzes each ad's
                performance and makes adjustments to improve your results.
              </p>
            </CardHeader>
            <CardContent>
              <div className="flex items-center justify-between mb-4">
                <p className="text-sm text-gray-500">Ad Preview {currentAdIndex + 1} of {previewData.adHeadings.length}</p>
                <div className="flex gap-2">
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={handlePrevAd}
                  >
                    <ChevronLeft className="h-4 w-4" />
                  </Button>
                  <Button 
                    variant="outline" 
                    size="sm"
                    onClick={handleNextAd}
                  >
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
              <div className="border p-4 rounded-md">
                <p className="text-sm text-gray-600">{url}</p>
                <motion.div
                  key={currentAdIndex}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <h3 className="text-blue-600 font-medium">
                    {previewData.adHeadings[currentAdIndex]}
                  </h3>
                  <p className="text-sm">
                    {previewData.adDescriptions[currentAdIndex]}
                  </p>
                </motion.div>
              </div>
            </CardContent>
          </Card>

          <Card>
            <CardHeader>
              <h2 className="text-xl font-semibold">How Multiply optimizes your ads</h2>
              <p className="text-sm text-gray-500">
                Multiply uses advanced analytics and AI to write different keywords and messages for
                your ads in order to maximize conversions.
              </p>
            </CardHeader>
            <CardContent>
              <h3 className="text-sm font-medium mb-2">Example keywords for your business</h3>
              <div className="flex flex-wrap gap-2">
                {previewData.keywords.map((keyword, index) => (
                  <span key={index} className="bg-green-100 text-green-800 px-2 py-1 rounded-full text-sm">
                    {keyword}
                  </span>
                ))}
              </div>
            </CardContent>
          </Card>
        </div>

        <div>
          <Card>
            <CardContent className="p-4">
              <ol className="list-decimal list-inside space-y-4">
                <li className="font-medium">
                  Ad previews
                  <p className="text-sm text-gray-500 ml-5">Take a look at the ads Multiply will generate for your business.</p>
                </li>
                <li className="font-medium">
                  Optimizations
                  <p className="text-sm text-gray-500 ml-5">Learn how Multiply will optimize your ads automatically.</p>
                </li>
                <li className="font-medium">
                  Get started
                  <p className="text-sm text-gray-500 ml-5">Get more leads and customers with Multiply today!</p>
                </li>
              </ol>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdPreview;